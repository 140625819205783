(function($) {
    "use strict"; // Start of use strict

    var owl_categories = $('.categories-wrapper > .owl-carousel');

    if(owl_categories.length){
        owl_categories.owlCarousel({
            margin:10,
            nav:true,
            dots:false,
            responsiveClass:true,
            stagePadding:45,
            navText:"",
            responsive:{
                0:{
                    items:1
                },
                460:{
                    items:2
                },
                660:{
                    items:3
                },
                860:{
                    items:4
                },
                1060:{
                    items:5
                },
                1260:{
                    items:6,
                    stagePadding:0
                }
            },
            onInitialized : function(){
                var active = $('.owl-item a',owl_categories).index($('a.active',owl_categories));
                console.log(active);
                if(active) {
                    setTimeout(function(){
                        owl_categories.trigger('to.owl.carousel',[3,0,true]);
                    },10);
                }
                else {
                    setTimeout(function(){
                        owl_categories.trigger('to.owl.carousel',[0,0,true]);
                    },10);
                }
            },
        });
    }

    var owl_testimonial = $('.testimonials > .owl-carousel');

    if(owl_testimonial.length){
        owl_testimonial.owlCarousel({
            margin:10,
            nav:true,
            dots:false,
            responsiveClass:true,
            stagePadding:45,
            navText:"",
            responsive:{
                0:{
                    items:1
                },
                400:{
                    items:2
                },
                768:{
                    items:3
                },
                1024:{
                    items:4
                },
                1280:{
                    items:5
                }
            },
            onInitialized : function(){
                var active = ($('.owl-item article a',owl_testimonial).index($('a[href='+location.hash+']',owl_testimonial)) >= 0 ? $('.owl-item article a',owl_testimonial).index($('a[href='+location.hash+']',owl_testimonial)) : 0);
                if(active) {
                    setTimeout(function(){
                        $('a[href='+location.hash+']').trigger('click');
                        owl_testimonial.trigger('to.owl.carousel',[active,0,true]);
                    },0);
                }
                else {
                    setTimeout(function(){
                        $('.owl-item:first-child article a',owl_testimonial).trigger('click');
                        owl_testimonial.trigger('to.owl.carousel',[0,0,true]);
                    },0);
                }
            },
        });
    }

    $('.owl').owlCarousel({
        navText:"",
        nav:true,
        items:1,
        loop:true,
        dots:true
    });

    $('.testimonials').find('.testimonial__footer').each(function(){
        $(this).on('click', function(e){
            var $_this = $(this);
            e.preventDefault();
            $('.testimonials').find('.testimonial').removeClass('owl-current');
            $_this.parent().addClass('owl-current');
            $('.testimonials').find('.testimonial__content').hide();
            var href = $_this.attr('href');
            $('.testimonials').find(href).show();
            var currentScroll = $(window).scrollTop();
            window.location.hash = href;
            $(window).scrollTop(currentScroll);
        });
    });

    $('.slickable').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        console.log(nextSlide);
        $('.testimonials').find('.testimonial__content').hide();
        var next = $('.testimonials').find('.testimonial:not(.slick-cloned)').eq(nextSlide);
        console.log(next);
        var id = $('a',next).attr('href');
        console.log(id);
        $('.testimonials').find(id).show();
    });

    /* Sticky subscription */

    var stickySub = $('#sticky-subscription'),
        stickyStop = $('#subscription'),
        stickPos = 4000;

    function stickySubscription() {
      if ( $(document).scrollTop() < stickPos  ) {
        if ( stickySub.hasClass('is-hidden') ) {
            stickySub.removeClass('is-hidden');
        }
    } else if ( $(document).scrollTop() > stickPos ) {
        if ( !stickySub.hasClass('is-hidden') ) {
            stickySub.addClass('is-hidden');
        }
      }
    }

    if ( stickySub.length ) {
        $(window).load(function() {
            stickPos = stickyStop.offset().top - $(window).outerHeight();
        });
        stickySubscription();
    }

    if ( stickySub.length ) {
        $(window).scroll(function() {
            stickySubscription();
        });
    }

    //  this is the function to track submits on the following forms
    $('[form-submit]').on('submit', function(){
        $(this).find('button').attr("disabled", "true");
        var currentForm = $(this).attr("form-submit");

        switch( currentForm ) {
            case "cpep-exam":
                ga('send', 'event', 'Formulaire', 'Inscription', 'Examen');
                break;
            case "newsletter":
                ga('send', 'event', 'Formulaire', 'Inscription', 'Infolettre');
                break;
            case "contactus":
                ga('send', 'event', 'Formulaire', 'Envoie', 'Demande de Contact');
                break;
            case "rendezvous":
                ga('send', 'event', 'Formulaire', 'Inscription', 'Rendez-vous');
                break;
        }
    });

    /**
     * Duplicate email, first name and last name fields in contact form (Nous joindre)
     * and appointment (Rendez-vous : entraînement privé et Formation coach de coach
     * for possible MailChimp Subscription.
     */
    $('#contact-form, #appointment-form').on('submit', function(){
        var $form = $(this);
        var suffix = $form.attr('id') === 'contact-form' ? 'contact' : 'rendezVous';

        // Email
        var $contactEmail = $form.find('input[name="questions['+suffix+'_courriel]"]');
        var contactEmail = $contactEmail.val();
        var $email = $contactEmail.next();
        $email.val(contactEmail);

        // First Name
        var $contactFirstName = $form.find('input[name="questions['+suffix+'_prenom]"]');
        var contactFirstName = $contactFirstName.val();
        var $firstName = $contactFirstName.next();
        $firstName.val(contactFirstName);

        // Last Name
        var $contactLastName = $form.find('input[name="questions['+suffix+'_nom]"]');
        var contactLastName = $contactLastName.val();
        var $lastName = $contactLastName.next();
        $lastName.val(contactLastName);
    });


    $('select[name="selection"]').on('change', function(){
        var $formation = $(this).find(':selected');
        var formationId = $formation.data('id');
        var formationSlug = $formation.data('slug');

        // Update subscribe button url
        var $subscribeButton = $formation.parents('.row').find('.btn--primary');
        var href = '/centre-etudiant/connexion/' + formationId + '-' + formationSlug;
        $subscribeButton.attr('href', href);

        // Update infos in case of a Formation continue
        var $table = $formation.parents('.row').find('table');

        if ($table.length > 0) {
            var date = $formation.data('date');
            var tarifReg = $formation.data('tarif-reg');
            var tarifEnt = $formation.data('tarif-ent');
            $table.find('#table-date').text(date);
            $table.find('#table-tarif-reg').text(tarifReg);
            $table.find('#table-tarif-ent').text(tarifEnt);
        }
    });

    $('#country_select').on('change', function(){
        var value = $(this).val();
        var html = '';
        if(value !== ''){
            var stateEl = $('#'+value+'_list');
            console.log(stateEl);
            html = stateEl.length ? stateEl.html() : $('#Other_list').html();
        }
        $('#state_select').html(html);
        $('#state_select').customSelect('rebuild');
    });

    window.Parsley.on('form:submit', function() {
        var form = this.$element.closest("form"),
            submit = form.find('[type="submit"]');
            submit.attr("disabled", "disabled");
    });
    // This function check if there is at least 10 digits in the input
    window.ParsleyValidator.addValidator('minDigits', function(value,requirement) {
    	   return value.replace(/[^0-9]/g,"").length >= requirement;
    });

    /*Password Strength*/
    function passwordStrength(password,minLength){

        var strongRegex = new RegExp("^(?=.{"+ minLength +",})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\\W).*$", "g");
        var mediumRegex = new RegExp("^(?=.{"+ minLength +",})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");
        var enoughRegex = new RegExp("(?=.{"+ minLength +",}).*", "g");


        if (!enoughRegex.test(password)) {
            return -1;
        }else if (strongRegex.test(password)) {
            return 2;
        }else if (mediumRegex.test(password)) {
            return 1;
        }
        else {
            return 0;
        }
    }

    var password = $('#password');
    password.on('keyup input',function() {
        var result = passwordStrength(password.val(),8);
        $('[gr-password-strengh]').attr('gr-password-strengh',result);
    });

    // PDF Banner functions

    // First step click
    $('.step-first .btn').click(function(e){
        e.preventDefault();

        $('.pdf-banner__step.step-first').toggleClass('is-away');
        $('.pdf-banner__step.step-second').toggleClass('is-active');

    });

    // Second step click
    $('.step-second .btn').click(function(e){
        e.preventDefault();
        if(!$('#contact-form').parsley().validate()){
            return false;
        }

        var listId = '83ba55d1bb';
        $.ajax({
            url:'/',
            method:'POST',
            data:{
                'action' :'website/mailchimpSubscribe/Subscribe',
                'email':$('#email_infolettre').val(),
                'infolettre': $('#infolettre').is(':checked') ? 1 : 0,
                'lid': listId
            },
            success: function(data) {
                window.location.href = '/actions/website/mailchimpSubscribe/pdf?fileId=' + $('#file').val();
            }
        });

        //  TO DO : Validate if the case is checked and then....
        $('.pdf-banner__step.step-second').toggleClass('is-away');
        $('.pdf-banner__step.step-third').toggleClass('is-active');
    });

    if ($('.faq-expander').length) {
        if (window.location.hash) {
            var hash = window.location.hash.replace('#', '');
            var faqId = window.location.hash.replace('#faq_', '');
            if ($('[data-faq-id="'+faqId+'"]').length) {
                $('[data-faq-id="'+faqId+'"]').find('input').prop("checked", true);

                var target = $('[name=' + hash +']');
                var offset = $('.nav-main').length ? $('.nav-main').outerHeight(true) : 0;

                if (target.length) {
                    $('body, html').stop().animate({
                        scrollTop: target.offset().top - offset
                    }, 720);
                }
            }
        }
    }



})(jQuery); // End of use strict
