/*!
 * jQuery Custom Select plugin
 * Author: Globalia
 * Contributor : Sebastien Dumont
 * Licensed under the WTFPL license
 */

;(function ($, win, doc) {
    "use strict";

    var pluginName = 'customSelect';

    $.cSelect = function (element, options, key) {

        var plugin = this;
        var defaults = {
            height: '450%'
        };

        plugin.element = element;
        plugin.$element = $(element);
        plugin.defaults = defaults;
        plugin.options = options;
        plugin.metadata = plugin.$element.data(pluginName);
        plugin.name = key;
        plugin.length = plugin.$element.children('option').length;

        plugin.init = function () {
            plugin.config = $.extend({}, plugin.defaults, plugin.options, plugin.metadata);
            plugin.$items = plugin.$element.find(plugin.config.target);
            plugin.build();
            return plugin;
        };

        plugin.build = function () {
            plugin.$element.addClass('select-hidden');
            plugin.$element.wrap('<div class="select"></div>');
            plugin.$element.after('<div class="select-styled"></div>');

            var $styledSelect = plugin.$element.next('div.select-styled');
            $styledSelect.text(plugin.$element.children('option').eq(0).text());

            var $list = $('<ul />', {
                'class': 'select-options'
            }).insertAfter($styledSelect);

            for (var i = 0; i < plugin.length; i++) {
                $('<li />', {
                    text: plugin.$element.children('option').eq(i).text(),
                    rel: plugin.$element.children('option').eq(i).val()
                }).appendTo($list);
            }

            var $listItems = $list.children('li');

            $styledSelect.click(function(e) {
                e.stopPropagation();
                var $styledOptions = $(this).next('ul.select-options'),
                    $styledOptionsHeight = $(this).outerHeight() * parseFloat(plugin.defaults.height);

                $('div.select-styled.is-active').not(this).each(function(){
                    $(this).removeClass('is-active');
                    $styledOptions.removeClass('is-active');
                });

                var $spaceToExpand = $(window).outerHeight() - ( $(this).offset().top - $(document).scrollTop() ) - $(this).outerHeight();

                if ( $spaceToExpand < $styledOptionsHeight ) {
                    $(this).parent().toggleClass('is-inverted');
                } else {
                    $(this).parent().removeClass('is-inverted');
                }

                $(this).toggleClass('is-active');
                $styledOptions.toggleClass('is-active');
            });

            $listItems.click(function(e) {
                e.stopPropagation();
                $styledSelect.text($(this).text()).removeClass('is-active');
                plugin.$element.val($(this).attr('rel'));
                $list.removeClass('is-active');
                plugin.$element.eq($(this).index()).prop('selected',true);
                plugin.$element.trigger('change');
            });

            $(doc).click(function() {
                $styledSelect.removeClass('is-active');
                $list.removeClass('is-active');
            });
        };

        plugin.rebuild = function () {
            plugin.$element.next('.select-styled').remove();
            plugin.$element.next('.select-options').remove();
            plugin.build();
        };

        plugin.callFunction = function (call, options) {
            if(typeof plugin[call] === 'function') {
                return plugin[call](options);
            }
        };

        plugin.init();
    };

    $.fn[pluginName] = function (call, options) {
        return this.each(function(){
            if(undefined === $(this).data('cSelect')) {
                if(typeof options !== 'undefined' && typeof options !== 'object')
                    return;
                var plugin = new $.cSelect(this, options);
                $(this).data('cSelect',plugin);
            } else {
                return $(this).data('cSelect').callFunction(call,options);
            }
        });
    };

    $(doc).on('ready domloaded ajaxloaded', function () {
        $(doc).find('.js-custom-select').customSelect();
    });

    // $(doc).find('.js-custom-select').on('change', function () {
    //     $(this).customSelect('rebuild');
    // });

}(jQuery, window, document));




$('.file-upload').each( function () {
    var $this = $(this);

    $this.find('input').on('change', function () {
        $this.find('label').addClass('is-ready');
    });

});


$("[data-parsley-validate]").length && $("[data-parsley-validate]").parsley({
    trigger:      'change',
    errorClass: "parsley-error",
    classHandler: function (el) {
        return el.$element.closest('.form-item, .form-item--wide');
    },
    errorsContainer: function(pEle) {
        var $err = pEle.$element.closest('.form-item').append();
        return $err;
    }
});
